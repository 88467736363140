.products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.products > .product {
    background-color: #fff;
    border: 1px solid #b8b8b8;
    margin-bottom: 2%;
    padding: 15px 15px 30px;
    transition: border-color 0.3s;
    width: 100%;

    &:hover {
        border-color: $primary-color;
    }
}

.product__link {
    color: $text-color;
    display: grid;
    grid-gap: 15px;
    grid-template-areas: "c1r1 c2"
    "c1r2 c2";
    grid-template-columns: 130px 1fr;
    grid-template-rows: 130px 1fr;
    height: 100%;
    text-decoration: none;
}

.product__media {
    grid-area: c1r1;
    margin-bottom: 10px;
    text-decoration: none;
    width: 100%;
}

.product__media img {
    display: block;
    max-width: 100%;
}

.product__tools {
    align-items: start;
    display: flex;
    grid-area: c1r2;
    justify-content: start;
    margin-bottom: 10px;
    min-height: 30px;
}

.product__tool {
    color: $text-color;
    font-size: 20px;
}

.product__info {
    color: $text-color;
    display: flex;
    flex-direction: column;
    grid-area: c2;
    height: 100%;
    text-decoration: none;
    width: 100%;
}

.product__title {
    color: $primary-color;
    flex-grow: 1;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    word-wrap: break-word;
}

.product--on-demand .product__title {
    flex-grow: 0;
}

.product__short-description {
    height: 84px;
}

.product__short-description li {
    margin-left: 20px;
}

.product__stock-status {
    margin-top: 20px;
    color: $secondary-color;
    font-weight: 600;
    height: 42px;
}

.product__price {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.product__price .price {
    color: $text-color-dark;
    font-size: 22px;
    font-weight: 700;
}

.products__banner {
    display: block;
    margin-bottom: 2%;
    width: 100%;
}

@media screen and (min-width: $screen-tablet) {
    .products > .product {
        margin-right: 2%;
    }

    .product__link {
        display: flex;
        flex-direction: column;
    }

    .product__media {
        margin-bottom: 10px;
    }

    .product__media img {
        margin: 0 auto;
    }

    .product__tools {
        align-items: center;
        justify-content: flex-end;
    }
}

@media screen and (min-width: $screen-tablet) and (max-width: $screen-medium - 1px) {
    .products > .product {
        width: 49%;

        &:nth-of-type(2n+2) {
            margin-right: 0;
        }
    }
}

@media screen and (min-width: $screen-medium) {
    .products > .product {
        width: 23.5%;

        &:nth-of-type(4n+4) {
            margin-right: 0;
        }
    }
}
