.customerservice {
  display: block;
  text-align: right;
  font-size: 13px;
  color: $text-color;
}

.customerservice .state {
  font-weight: bold;
  color: $primary-color;
}

.phonenumber {
  display: inline-block;
}