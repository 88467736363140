.button,
button {
  background-color: $secondary-color;
  border: none;
  color: $text-color-light;
  cursor: pointer;
  font-family: $secondary-font;
  font-size: 22px;
  font-weight: 700;
  padding: 10px 15px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s;

  &:focus {
    outline: none;
  }

  &:not(.thumbnail__button):hover {
    background-color: $primary-color;
  }
}

.button--secondary,
.checkout-index-index button.action-cancel {
  background-color: $primary-color;
  color: $text-color-light;
  cursor: pointer;
  font-family: $primary-font;
  font-size: 17px;
  font-weight: 700;
  padding: 10px 15px;
  text-decoration: none;
  text-transform: initial;
  transition: all 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $secondary-color;
  }
}

.button--text {
  color: $text-color-dark;
  text-decoration: none;
}

.button--sparkle,
button.primary.checkout {
  background-image: url('../images/sparkle.png');
  background-position: 104% -5px;
  background-repeat: no-repeat;
  padding: 10px 35px 10px 15px;
}

.button--sparkle {
    display: inline-block;
}

.button--circle {
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 26px;
  height: 50px;
  justify-content: center;
  width: 50px;
}

.button--full-width {
  width: 100%;
  display: block;
  text-align: center;
}

.actions-toolbar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.actions-toolbar {
  &~ .primary,
  &~ .secondary {
    display: inline-block;
  }
}

.button__qty {
    background: $tertiary-color;
    color: $primary-color;
    font-size: 14px;
    padding: 5px 10px;

    &:hover {
        background: $quaternary-color !important;
        color: $primary-color;
    }
}
