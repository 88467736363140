.gallery__main {
    padding-left: 0 !important;
}

.gallery__thumbnails {
    display: flex;
    gap: 1rem;
    height: auto !important;
}

.gallery__thumbnail {
    flex: 0 0 calc(33.333% - 0.666rem);
    margin: 0 !important;
}
