* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: $body-background;
}

.page-wrap {
  overflow: hidden;
}

.show-mobile {
  display: block;
}

.hide-mobile {
  display: none;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

.loading-mask {
  position: fixed !important;
}

@media screen and (min-width: $screen-small) {
  .show-mobile {
    display: none;
  }

  .hide-mobile {
    display: block;
  }
}
