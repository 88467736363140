.loginoptions {
  display: flex;
  @media screen and (max-width: $screen-tablet) {
    display: block;
  }
  justify-content: space-between;
  margin-bottom: 25px;
}

.loginoption__block {
  display: inline-block;
  vertical-align: top;
  margin-top: 25px;
  width: 49%;
  @media screen and (max-width: $screen-tablet) {
    width: 100%;
  }
}

.loginoption__title {
  color: $primary-color;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
}

.loginoption__actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

#remember-me-box input,
#remember-me-box label {
  display: inline-block;
}