.block.filter .filter-options-content ol {
    list-style: none;
    margin: 0 !important;
}

.filter__title:not(.wishlist__title) {
    line-height: 1;
    margin-bottom: 20px;
}

/* Current filters */
.block.filter .filter-current ol {
    list-style: none;
    margin-bottom: 0;
}

.block.filter .filter-current .filter-current-subtitle {
    font-weight: bold;
}

.block.filter .filter-current .filter-current-subtitle,
.block.filter .filter-current .filter-label,
.block.filter .filter-current .filter-value {
    font-family: $secondary-font;
    font-size: 14px;
    word-break: break-word;
}

.block.filter .filter-current .filter-label:after {
    content: ":";
}

.block.filter .filter-current .action.remove {
    &::after {
        font-family: $icon-font;
        color: $secondary-color;
    }

    &:hover {
        text-decoration: none;
    }

    &:hover::after {
        color: $primary-color;
    }

    span {
        display: none;
    }
}

/* Filter actions */
.block-actions.filter-actions {
    margin-bottom: 20px;
}

/* Filter options */
.filter-options-title {
    font-family: $secondary-font;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 12px;
    word-break: break-word;
    color: $primary-color;
}

.block.filter li.item {
    position: relative;
    line-height: 26px;
}

.block.filter .item > a:not(.remove) {
    align-items: center;
    display: flex;
    font-size: 14px;
    gap: 7px;
    line-height: 1;
    color: $text-color;
    text-decoration: none;
}

.block.filter .item:not(:last-child) > a {
    margin-bottom: 12px;
}

.block.filter li.item input {
    cursor: pointer;
}

.block.filter li.item .price {
    color: $text-color-dark;
    font-family: inherit;
    font-size: inherit;
    font-weight: normal;
    display: inline-block;
}

.block.filter .custom-checkbox {
    position: absolute;
    border: 1px solid $text-color-dark;
    border-radius: 2px;
    top: 5px;
    left: 0;
    height: 13px;
    width: 13px;
    transition: box-shadow 0.15s ease;
    overflow: hidden;

    &:hover {
        background-color: $text-color-dark;
    }
}

.block.filter .am_shopby_link_selected {
    font-weight: normal;
}

.block.filter .am_shopby_link_selected .custom-checkbox:after {
    position: absolute;
    left: 0;
    width: 13px;
    height: 13px;
    content: "";
    background-color: $text-color-dark;
}

.block.filter li.item .label {
    font-weight: normal;
    display: inline-block;
    line-height: 25px;
    font-size: 14px;
    color: $text-color-dark;
}

/* Show more button */
.block.filter .filter-group {
    display: flex;
    flex-direction: column;
}

.block.filter .filter-group .filter-group-title {
    order: 2;
    font-weight: bold;
    cursor: pointer;
}

.block.filter .filter-group:not(.active) .filter-group-title .active {
    display: none;
}

.filter-group.active .filter-group-title .inactive {
    display: none;
}

.filter-group .filter-group-content {
    order: 1;
}

.filter__title:focus {
    outline: none;
}

.filter__block {
    background-color: #fff;
    border: 1px solid #b8b8b8;
    margin-bottom: 20px;
    padding: 15px;
}

.block.filter li.item {
    margin-left: 0;
}

.block-content .filter-options input[type='checkbox'] + .amshopby-choice:before {
    border: 1px solid #787878;
}

.block-content .filter-options input[type='checkbox'] + .amshopby-choice:hover:before {
    border: 1px solid #000000;
}

.block.filter .item > a.amshopby-remove {
    padding-left: 0;
}

.block.filter .action.show-more,
.block.filter .action.show-less {
    color: $text-color-dark;
    cursor: pointer;
    display: block;
    font-weight: 700;
    margin-top: 10px;
    text-transform: uppercase;
}

@media screen and (max-width: $screen-tablet - 1px) {
    .filter-content {
        display: none;
    }

    .filter-active {
        position: relative;
    }

    #filters {
        width: 45%;
    }

    .filter-active #filters {
        background-color: #fff;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 56px;
        width: 100%;
        z-index: 20;
        overflow: scroll;
    }

    .filter__title {
        border: 1px solid #ccc;
        color: $text-color;
        font-family: $primary-font;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 0 !important;
        padding: 10px 39px 10px 9px;
        position: relative;

        &:after {
            content: '\f078';
            font-family: $icon-font;
            font-weight: 700;
            pointer-events: none;
            position: absolute;
            right: 13px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .filter-active .filter__title {
        border: none;
        color: $primary-color;
        font-size: 16px;
        font-weight: 700;
        padding: 25px 2.5%;

        &:after {
            content: '\f00d';
            font-weight: 400;
            right: 2.5%;
        }
    }

    .filter-active .filter-content {
        display: block;
        padding: 0 2.5% 25px;
    }

    .block-content .filter-options input[type='checkbox'] + .amshopby-choice:before, .filter-options input[type='checkbox'] + .amshopby-choice:after {
        width: 15px !important;
        height: 15px !important;
    }

    .block-content .filter-options input[type='checkbox'] + .amshopby-choice:after {
        background: #1979c3 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxNSAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xMS42NjkzIDMuNTAwNjFjLS4yMDc4LjAwNjk1LS40MDQ4LjA5ODg1LS41NDkzLjI1NjItMS45NTcwMyAyLjA2NDE1LTMuNDM4ODMgMy43NzY1NS01LjI4MDQxIDUuNzQ3ODNMMy44MTczIDcuNzA0NzRjLS4wNzk5Mi0uMDcxNi0uMTcyNDUtLjEyNTk1LS4yNzIzMy0uMTU5OTItLjA5OTg3LS4wMzM5Ny0uMjA1MTItLjA0NjktLjMwOTc0LS4wMzgwNi0uMTA0NjIuMDA4ODUtLjIwNjU2LjAzOTI5LS4zLjA4OTYtLjA5MzQ1LjA1MDMtLjE3NjU2LjExOTQ4LS4yNDQ1OS4yMDM2LS4wNjgwNC4wODQxMS0uMTE5NjYuMTgxNTEtLjE1MTkzLjI4NjYyLS4wMzIyNy4xMDUxMS0uMDQ0NTUuMjE1ODgtLjAzNjE1LjMyNTk4LjAwODQxLjExMDExLjAzNzM0LjIxNzQuMDg1MTUuMzE1NzQuMDQ3ODEuMDk4MzMuMTEzNTUuMTg1NzkuMTkzNDguMjU3MzlMNS4zNzc3MSAxMS4yOThjLjE1MzE1LjEzNzkuMzUwMTkuMjA5OC41NTE2LjIwMTMuMjAxNDEtLjAwODYuMzkyMzEtLjA5NjkuNTM0NDQtLjI0NzNDOC42MTA3NCA4Ljk4NzU3IDEwLjE0MiA3LjE3NTQ0IDEyLjI1NiA0Ljk0NTc4Yy4xMTY1LS4xMTgzNy4xOTYtLjI3MTIuMjI4MS0uNDM4MjMuMDMyMS0uMTY3MDMuMDE1My0uMzQwMzgtLjA0ODQtLjQ5NzA5LS4wNjM2LS4xNTY3MS0uMTcwOS0uMjg5MzctLjMwNzgtLjM4MDQyLS4xMzY5LS4wOTEwNS0uMjk2OC0uMTM2MTktLjQ1ODYtLjEyOTQzeiIvPgo8L3N2Zz4K) center / 15px !important;
    }
}

@media screen and (min-width: $screen-tablet) {
    .filter__title:not(.wishlist__title) {
        background-color: #fff;
        border: 1px solid #b8b8b8;
        color: $primary-color;
        padding: 15px;
    }
}
