.block-wishlist {
    background: #fff;
    border: 1px solid #b8b8b8;
    margin-bottom: 20px;
    padding: 15px;

    .block-content {
        display: block !important;
    }
}

.wishlist__title {
    color: $primary-color;
    font-family: $secondary-font !important;
    font-size: 18px !important;
    font-weight: 700;
    margin-bottom: 3px;
    word-break: break-word;
}

.product-items-sidebar {
    margin-bottom: 15px;

    .product-item-link {
        color: $text-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.single-product__wishlist {
    margin-top: 5px;
    text-align: right;
}

.wishlist__button {
    color: $text-color;
    text-decoration: none;

    &:hover {
        color: $secondary-color;
        text-decoration: underline;
    }
}

.account .amwishlist-newlist-block .amwishlist-button.-link.-clear.-plus {
    padding-bottom: 0;
    padding-top: 15px;
}

.account div[data-amwishlist-js="tabs-block"] {
    display: none !important;
}

.account .amwishlist-newlist-block .amwishlist-newlist {
    padding: 20px 0 0 !important;
}

button > span,
.button > span {
    display: block;
}

.amwishlist-popup-block {
    .amwishlist-tabs-block.-title .amwishlist-item.-active {
        border-bottom-color: $primary-color;
    }

    .amwishlist-button.-link {
        color: $primary-color;

        &:hover {
            color: $primary-color;
        }
    }

    .amwishlist-list-block .amwishlist-list .amwishlist-item.-active,
    .amwishlist-list-block .amwishlist-list .amwishlist-item:active {
        border-color: $primary-color;
    }

    .amwishlist-button.-fill.-primary:not(.-disabled) {
        background: $secondary-color;
        border-color: $secondary-color;

        &:hover {
            background: $primary-color;
            border-color: $primary-color;
        }
    }

    .amwishlist-newlist-block .amwishlist-button.-add {
        max-width: none;
    }

    .amwishlist-newlist-block .amwishlist-input {
        border: 1px solid $border-color !important;
    }

    .amwishlist-popup-block .amwishlist-toolbar.-header .amwishlist-button.-close:after,
    .amwishlist-popup-block .amwishlist-toolbar.-header .amwishlist-button.-close:before {
        background: #000;
    }
}

.amwishlist-wishlist-index .content__main {
    margin-bottom: 20px;
}

.content__main {
    .amwishlist-tabs-block.-title {
        margin-top: 30px;
    }

    .amwishlist-list-grid .amwishlist-list {
        display: grid;
        gap: 13px;
        grid-template-columns: repeat(2, 1fr);
        margin: 0;
    }

    .amwishlist-list-grid .amwishlist-list > .amwishlist-item {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .amwishlist-list-grid .amwishlist-preview-block {
        aspect-ratio: 1 / 1;
        border-color: #b8b8b8;
        border-radius: 0;
        margin: 0;
        min-height: unset;
    }

    .amwishlist-list-grid .amwishlist-preview-block .amwishlist-image {
        height: auto;
        max-height: calc(50% - 7px * 2);
    }

    .amwishlist-image-wrap {
        position: relative;

        & > a {
            text-decoration: none;
        }
    }

    div[data-amwishlist-js] {
        margin-bottom: 50px;
    }

    .amwishlist-button.-clear:not(.-plus):not(.-share):not(.-update):not(.-prev) {
        color: $text-color-dark;
        display: block;
        font-size: 18px;
        line-height: 1;
        position: absolute;
        right: 15px;
        text-decoration: none;
        top: 13px;
    }

    .amwishlist-button.-fill.-primary:not(.-disabled) {
        @extend .button;
        @extend .button--circle;

        background: $secondary-color;
        margin-top: 0;
        min-width: unset;
    }

    .amwishlist-newlist .amwishlist-button.-fill.-primary:not(.-disabled) {
        border-radius: unset;
        font-size: 22px;
        height: auto;
        line-height: 1;
        max-width: unset;
        padding: 10px 15px;
        width: auto;
    }

    .amwishlist-newlist-block .amwishlist-input {
        border: 1px solid $border-color;
        height: 42px;
    }

    .amwishlist-product-grid .amwishlist-input.-qty {
        border: 1px solid $border-color;
    }

    .wishist-cart-wrap {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .amwishlist-product-grid .amwishlist-field {
            margin-bottom: 0;
        }
    }

    .amwishlist-button.-prev {
        color: $text-color !important;
        margin-right: 10px;
        padding-bottom: 0 !important;
    }

    .amwishlist-button.-prev:before {
        content: unset;
    }

    .amwishlist-item article {
        align-items: center;
        display: flex;
        gap: 15px;
        justify-content: space-between;
        margin-top: 15px;
    }

    .amwishlist-list-grid .amwishlist-title {
        margin: 0;
    }

    .amwishlist-list-grid .amwishlist-name {
        line-height: 1.2;
    }

    .amwishlist-tabs-block.-title .amwishlist-item.-active {
        border-bottom-color: $primary-color;
    }

    .amwishlist-button.-link {
        color: $primary-color;
    }

    .amwishlist-product-grid > .amwishlist-items {
        display: grid;
        gap: 13px;
        grid-template-columns: repeat(2, 1fr);
    }

    .amwishlist-product-grid > .amwishlist-items > .amwishlist-item {
        background: #fff;
        border: 1px solid $border-color;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0;
        max-width: none;
    }

    .amwishlist-product-grid .amwishlist-name {
        @extend .product__title;
        text-decoration: none;
        margin-bottom: 0 !important;
    }

    .amwishlist-product-grid .product-item-comment {
        width: 100%;
    }

    .price-container {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 10px;
    }

    .price-excluding-tax > .price {
        color: $text-color-dark;
        font-size: 22px;
        font-weight: 700;
    }

    .price-including-tax > .price {
        color: $text-support-color;
        font-family: $primary-font;
        font-weight: 400;

        &:after {
            content: ' incl. BTW';
        }
    }

    .amwishlist-product-grid .amwishlist-field {
        margin-bottom: 0;
    }
}

.amwishlist-wishlist-index .pager {
    margin-top: 50px;
}

.amwishlist-wishlist-index .pager .limiter {
    margin-top: 0 !important;
}

.form-wishlist-items .product-items {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, calc(50% - 10px));
    list-style: none;
    margin: 20px 0;

    .product-item {
        background: #fff;
        border: 1px solid #b8b8b8;
        margin-left: 0 !important;
        padding: 15px 15px 30px;

        .product-item-info {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .product-item-photo {
            display: block;
            margin-bottom: 10px;
        }

        .price-container {
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: 10px;
        }

        .price-excluding-tax .price {
            color: #000;
            font-family: $primary-font;
            font-size: 22px;
            font-weight: 700;
        }

        .price-including-tax .price {
            color: #838383;
            font-family: $primary-font;
            font-size: 14px;
            font-weight: 400;

            &:after {
                content: ' incl. BTW';
            }
        }

        .product__sku {
            margin-bottom: 15px;
        }

        textarea {
            width: 100%;
        }

        .box-tocart > .fieldset {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        .product-item-actions .action:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.product__title > a {
    color: inherit;
    text-decoration: none;
}

.wishlist-toolbar .pager {
    display: flex;
    justify-content: space-between;

    .toolbar__item {
        flex-basis: auto;
        width: auto;
    }
}

.amwishlist-title-block {
    margin-top: 30px;

    > .amwishlist-title-block__holder {
        align-items: center;
        display: flex;
        margin-bottom: 5px;
    }
}

.wishlist-top-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 10px 5px;

    .amwishlist-button.button--primary {
        position: relative !important;
        color: $text-color !important;
        font-size: 16px !important;
        height: auto !important;
    }

    .amwishlist-button.button--primary.-basket {
        display: flex !important;
        right: 0 !important;
        top: 0 !important;
    }
}

.form.wishlist.share {
    .fieldset {
        .field.emails.required,
        .field.text {
            .control textarea {
                width: 100%;
                resize: none;
                margin: 5px 0 10px;
            }
        }
    }
}

.wishlist-sku {
    margin-bottom: 15px !important;

    span {
        margin-right: 5px;
    }
}

@media screen and (min-width: 500px) {
    .wishlist-top-toolbar {
        .amwishlist-button.button--primary.-basket {
            margin-left: auto !important;
        }
    }
}

@media screen and (max-width: $screen-medium - 1px) {
    .wishlist-index-index .actions-toolbar {
        flex-direction: column;

        .button:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

@media screen and (min-width: $screen-small) {
    .content__main .amwishlist-list-grid .amwishlist-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: $screen-medium) {
    .content__main .amwishlist-list-grid .amwishlist-list {
        grid-template-columns: repeat(4, 1fr);
    }

    .form-wishlist-items .product-items {
        grid-template-columns: repeat(4, calc(25% - 15px));
    }

    .content__main .amwishlist-product-grid > .amwishlist-items {
        grid-template-columns: repeat(4, 1fr);
    }
}
