#mobile-nav-toggle {
  color: $text-color-light;
  font-size: 28px;
  text-decoration: none;
}

.navigation__overlay {
  background-color: $mega-menu-overlay;
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
}

.navigation__items {
  align-items: center;
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.navigation__item {
  color: $text-color-light;
  font-size: 17px;
  line-height: 1;
  margin-left: 0;

  &--active {
    background-color: $text-color-light;
    color: $primary-color;
  }
}

.navigation__toggle {
  background-color: transparent;
  cursor: pointer;
  display: block;
  padding: 20px 15px;
  transition: background-color 0.3s;

  &:hover {
    background-color: $text-color-light;
    color: $primary-color;
  }
}

.navigation__sub-menu {
  background-color: $mega-menu-background;
  display: none;
  left: 0;
  padding: 40px 0;
  position: absolute;
  right: 0;
  top: 100%;
}

.sub-menu__columns {
  column-count: 4;
}

.sub-menu__category {
  break-inside: avoid;
  list-style: none;
  margin-top: 20px;
  page-break-inside: avoid;

  &:first-child {
    margin-top: 0;
  }
}

.sub-menu__item {
  margin-left: 0;
}

.sub-menu__link {
  color: $text-color;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.sub-menu__link--top-level {
  color: $primary-color;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.sub-menu__link--parent {
  color: $primary-color;
  font-size: 17px;
  font-weight: 700;
}

#mobile-nav {
  --mm-color-button: #003da6;
  --mm-color-background: #e5e8ec;
  --mm-color-text: #003da6;
}

.mobile-nav {
  display: none;
}

@media screen and (min-width: $screen-tablet) {

}

@media screen and (min-width: $screen-small) {
  .navigation__item {
    font-size: 15px;
  }
}

@media screen and (min-width: $screen-medium) {
  .navigation__item {
    font-size: 17px;
  }
}

@media screen and (min-width: $screen-large) {

}
