.category-header {
  background-color: #fff;
  margin-bottom: 35px;
  padding: 10px 0 35px;
}

.category-header__wrap.container {
  display: flex;
  flex-direction: column;
}

.category-header__sidebar {
  display: none;
}

.category-usp {
  list-style: none;
  max-width: 190px;
}

.category-usp > ul {
  list-style: none;
  padding-left: 20px;
}

.category-usp li:not(:last-child) {
  margin-bottom: 5px;
}

.category-usp:not(.category-usp--highlight) {
  li {
    position: relative;

    &:before {
      color: $secondary-color;
      content: '\f00c';
      font-family: $icon-font;
      left: -20px;
      position: absolute;
      top: 1px;
    }
  }

  strong {
    color: $secondary-color;
    font-weight: 700;
  }
}

.category-usp--highlight {
  background-image: url(../images/blob.svg);
  background-repeat: no-repeat;
  background-size: 100% 240px;
  padding: 30px 14px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.category-usp--highlight > ul,
.content__sidebar .category-usp--highlight > ul {
  list-style: disc;
}

.category-usp--highlight li,
.category-usp--highlight a {
  color: $primary-color;
  margin-left: 0;
}

.category-header .breadcrumbs {
  padding: 0 0 10px;
}

.category-header .page-title {
  color: $primary-color;
  font-size: 22px;
  font-weight: 700;
  padding: 20px 0;
}

@media screen and (min-width: $screen-tablet) {
  .category-header__wrap.container {
    flex-direction: row;
    justify-content: space-between;
  }

  .category-header__sidebar {
    display: block;
    padding-top: 30px;
    width: 32.5%;
  }

  .category-header__content {
    width: 65.5%;
  }

  .category-usp--highlight {
    background-size: 100% 200px;
  }
}

@media screen and (min-width: $screen-medium) {

  .category-header__sidebar {
    width: 19%;
  }

  .category-header__content {
    width: 79%;
  }
}
