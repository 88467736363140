.footer-top-bar {
  background-color: $footer-top-bar-background;
  padding: 15px 0;
}

.footer-top-bar__items {
  align-items: center;
  display: flex;
}

.footer-top-bar__logo {
  align-items: center;
  display: flex;
  margin-right: 45px;
}

.footer-middle-bar {
  background-color: $footer-middle-bar-background;
  padding: 50px 0;
}

.footer-middle-bar__items {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.footer-middle-bar__item {
  width: 100%;
}

.footer-middle-bar__block:not(:last-child) {
  margin-bottom: 25px;
}

.footer-middle-bar__title {
  color: $text-color-light;
  font-size: 20px;
  font-weight: 700;
}

.toggle-mobile--active .toggle-arrow {
  display: none;
}

.toggle-mobile .toggle-arrow--active {
  display: none;
}

.toggle-mobile--active .toggle-arrow--active {
  display: block;
}

.footer-middle-bar__content ul {
  list-style: none;

  & > li {
    margin-left: 0;
  }
}

.footer-middle-bar__content a {
  color: $text-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.footer-bottom-bar {
  background-color: $footer-bottom-bar-background;
  padding: 20px 0;
}

.footer-bottom-bar__items {
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer-bottom-bar__item > span {
  margin-left: 4px;
}

.footer-bottom-bar__payment-methods img {
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle;
}

@media screen and (max-width: $screen-tablet - 1px) {
  .footer-top-bar__logo {
    margin-right: 0;
    max-width: 430px;
  }

  .footer-top-bar__logo > .logo__image {
    width: 73%;
  }

  .footer-top-bar__logo > .logo__emblem {
    width: 23%;
  }

  .footer-middle-bar__items.container {
    width: 100%;
  }

  .footer-middle-bar__item {
    padding-left: 2.5%;
    padding-right: 2.5%;

    &:first-child {
      margin-bottom: 25px;
    }

    &:not(:first-child) {
      border-top: 1px solid $text-support-color;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    &:last-child {
      border-bottom: 1px solid $text-support-color;
    }
  }

  .toggle-mobile {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .toggle-arrow,
  .toggle-arrow--active {
    color: $text-color;
  }

  .toggle-content {
    overflow: hidden;
    transition: height 0.3s;

    &:not(.toggle-content--active) {
      display: none;
    }
  }

  .footer-bottom-bar__items {
    flex-wrap: wrap;
    text-align: center;
  }

  .footer-bottom-bar__item {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

@media screen and (min-width: $screen-tablet) {
  .footer-middle-bar__title {
    color: $primary-color;
  }

  .toggle-mobile .toggle-arrow,
  .toggle-mobile .toggle-arrow--active {
    display: none;
  }
}

@media screen and (min-width: $screen-tablet) and (max-width: $screen-small - 1px) {
  .footer-middle-bar__items {
    justify-content: center;
  }

  .footer-middle-bar__item {
    margin-right: 3%;
    width: 31.33%;

    &:last-child,
    &:nth-child(3n+3) {
      margin-right: 0;
    }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}

@media screen and (min-width: $screen-small) {
  .footer-middle-bar__items {
    justify-content: space-between;
  }

  .footer-middle-bar__item {
    width: 23%;
  }
}

@media screen and (min-width: $screen-large) {

}
