body.blog-page #maincontent {
    .post-featured-image {
        margin-bottom: 30px;
    }

    .blog-related-title {
        font-size: 18px;
        margin-bottom: 10px;
        display: block;
    }

    ol.block-content {
        list-style: none;

        li {
            margin-left: 0;

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }

            .post-item-link {
                display: block;
                background-color: #fff;
                padding: 15px;
                color: $text-color;
                text-decoration: none;
                font-size: 16px;
                font-weight: 600;
            }

            .blog-related-image {
                height: 150px;
                background-size: cover;
                background-position: center center;
                margin-bottom: 10px;
            }
        }
    }

    .post-description {
        [data-content-type="text"] {
            p {
                font-size: 14px;
            }
        }

        [data-content-type="text"]:not(:last-of-type) {
            p {
                margin-bottom: 15px;
            }
        }

        h2 {
            margin-bottom: 10px;
            font-size: 22px;
        }

        .pagebuilder-mobile-hidden {
            display: none;
        }

        [data-content-type="column-line"] {
            gap: 30px;

            [data-content-type="image"] {
                img {
                    margin: 0;
                }
            }
        }

        [data-content-type="button-item"] {
            a {
                text-decoration: none;
                text-transform: uppercase;
                color: #fff;
                background-color: $secondary-color;
                padding: 10px;
                margin: 10px 0;
                font-weight: 700;
                font-size: 20px;
                width: fit-content;
                display: block;

                &:hover {
                    background-color: $primary-color;
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-small) {
    body.blog-page #maincontent {
        .post-description {
            [data-content-type="column-line"] {
                gap: 15px;
                flex-direction: column-reverse;

                [data-content-type="column"] {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-tablet) {
    body.blog-page #maincontent {
        .post-holder {
            margin-bottom: 0;
        }

        ol.block-content {
            li {
                .blog-related-image {
                    height: 200px;
                }
            }
        }

        .post-description {
            h2 {
                font-size: 18px;
            }

            [data-content-type="column-line"] {
                gap: 30px;

                [data-content-type="image"] {
                    img {
                        margin: 0;
                    }
                }
            }
        }
    }
}
