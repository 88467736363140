.label {
  color: $primary-color;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 2px;
}

.input-field,
.input-text,
.field select {
  border: $input-border;
  border-radius: 0;
  color: $text-color;
  font-size: $input-font-size;
  padding: $input-padding;
  width: 100%;
  margin-bottom: 15px;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $tertiary-color;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
  max-width: 68px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }
}

input.mage-error {
  border: 1px solid #FF0000;
}

.input-icon {
  position: absolute;
  padding: 15px 15px 15px 15px;
  font-size: 18px;

  &~ .input-field {
    padding: $input-icon-padding;
  }
}

.field.note {
  padding: 10px 0 20px 0;
}

.field._required > .label,
.field.required > label {
  position: relative;

  &:after {
    color: $primary-color;
    content: '*'
  }
}

.field._error .input-text {
  border-color: #f00;
}

.input-text:not(:only-child) {
  margin-bottom: 15px;
}

.control .note {
  display: block;
  margin-bottom: 15px;
}

textarea {
  border: $input-border;
  border-radius: 0;
  font-family: $primary-font;
  font-size: $input-font-size;
  padding: $input-padding;
  resize: vertical;
}

fieldset {
  border: 0;
}
