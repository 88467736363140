.cms-page-view .page-title {
  color: $primary-color;
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 62px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
}

.cms-page-view p img {
  max-width: 500px;
  margin: 20px 0;
}

@media only screen and (max-width: 768px) {
  .cms-page-view p img {
    max-width: 100%;
    float: none;
    margin: 20px 0 0 0;
  }

  .cms-page-view p {
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: $screen-tablet - 1px) {
  .cms-page-view .page-title {
    font-size: 35px;
  }
}
