#html-body .content__main {
    [data-content-type="row"] {
        padding: 1rem 0;
    }

    [data-content-type="heading"] {
        margin-bottom: 1rem;
    }

    [data-content-type="column-group"] {
        [data-content-type="column-line"] {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            [data-content-type="column"] {
                width: 100%;
            }
        }
    }

    [data-content-type="text"] {
        p:not(:last-of-type) {
            margin-bottom: 1rem;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }

    [data-content-type="image"] {
        .pagebuilder-mobile-hidden {
            display: none;
        }
    }

    [data-content-type="video"] {
        iframe {
            width: 100%;
            height: 300px;
        }
    }

    [data-content-type="products"] {
        .block-products-list.grid .products-grid {
            .product-items {
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;
                list-style: none;
            }
        }
        .product-item {
            background-color: #fff;
            border: 1px solid #b8b8b8;
            padding: 1rem;
            margin: 0;
            transition: border-color 0.3s;
            width: 100%;

            &:hover {
                border-color: $primary-color;
            }
        }

        .product-item-photo {
            grid-area: c1r1;
        }

        .product-image-photo {
            width: 125px;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }

        .product-item-info {
            display: grid;
            grid-gap: 15px;
            grid-template-areas:
                    "c1r1 c2"
                    "c1r2 c2";
            grid-template-columns: 130px 1fr;
            grid-template-rows: 130px 1fr;
            height: 100%;
            text-decoration: none;
        }

        .product-item-details {
            display: flex;
            flex-direction: column;
            height: 100%;
            text-decoration: none;
            width: 100%;
            grid-area: c2;
        }

        .product-item-name {
            flex-grow: 1;
            margin-bottom: 15px;

            a {
                color: $primary-color;
                font-size: 18px;
                font-weight: 700;
                line-height: 1.2;
                margin-bottom: 20px;
                word-wrap: break-word;
                text-decoration: none;
            }
        }

        .actions-primary {
            margin-top: 1rem;
        }

        .actions-secondary {
            margin-top: 1rem;
        }

        .action.tocompare {
            display: none;
        }

        .slick-list.draggable {
            margin: 0 -1rem;

            .slick-track {
                display: flex;

                .slick-slide {
                    margin: 0 1rem;
                    height: 100%;

                    > div {
                        height: 100%;

                        > li {
                            height: 100%;
                        }
                    }

                    .product-item-name {
                        min-height: 5rem;
                    }

                    .action.tocart.primary {
                        font-size: 1rem;
                    }
                }

                .product-item-info {
                    display: flex;
                    flex-direction: column;
                }

                .product-image-photo {
                    margin: 0 auto;
                }
            }
        }

        .slick-dots {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            list-style: none;
        }
    }
}

@media only screen and (min-width: $screen-tablet) {
    #html-body .content__main {
        [data-content-type="products"] {
            .block-products-list.grid .products-grid {
                .product-items {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }

        [data-content-type="video"] {
            iframe {
                height: 480px;
            }
        }

        [data-content-type="products"] {
            .slick-list.draggable {
                .slick-track {
                    .slick-slide {
                        .product-item-name {
                            min-height: 7rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $screen-small) {
    #html-body .content__main {
        [data-content-type="row"] {
            padding: 3rem 0;
        }

        [data-content-type="column-group"] {
            [data-content-type="column-line"] {
                flex-direction: row;
                gap: 2rem;
            }
        }

        [data-content-type="products"] {
            .block-products-list.grid .products-grid {
                .product-items {
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }

            .product-image-photo {
                margin: 0 auto;
                width: 200px;
            }

            .product-item-info {
                display: flex;
                flex-direction: column;

                .product-item-inner {
                    .action.tocart.primary {
                        width: 100%;
                    }
                }
            }
        }

        [data-content-type="products"] {
            .slick-list.draggable {
                .slick-track {
                    .slick-slide {
                        .product-item-name {
                            min-height: 5rem;
                        }
                    }
                }
            }
        }

        [data-content-type="image"] {
            .pagebuilder-mobile-hidden {
                display: block;
            }

            .pagebuilder-mobile-only {
                display: none;
            }
        }

        [data-content-type="video"] {
            iframe {
                height: 600px;
            }
        }
    }
}

@media only screen and (min-width: 1028px) {
    #html-body .content__main {
        [data-content-type="products"] {
            .slick-list.draggable {
                .slick-track {
                    .slick-slide {
                        .product-item-name {
                            min-height: 7rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $screen-medium) {
    #html-body .content__main {
        [data-content-type="video"] {
            iframe {
                height: 680px;
            }
        }

        [data-content-type="products"] {
            .block-products-list.grid .products-grid {
                .product-items {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }
            }
        }

        [data-content-type="products"] {
            .slick-list.draggable {
                .slick-track {
                    .slick-slide {
                        .product-item-name {
                            min-height: 5rem;
                        }
                    }
                }
            }
        }
    }
}
