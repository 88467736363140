.product-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-options > .field {
  margin-bottom: 15px;
  width: 48%;

  &:last-child {
    margin-bottom: 35px;
  }
}

.product-options .control {
  position: relative;

  &:after {
    background-color: #fff;
    bottom: 16px;
    color: $primary-color;
    content: '\f107';
    font-family: $icon-font;
    font-size: 20px;
    padding-left: 10px;
    pointer-events: none;
    position: absolute;
    right: 10px;
  }
}

.product-options .product-custom-option {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  padding: 8px 10px;
  width: 100%;

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    color: #ccc;
  }
}