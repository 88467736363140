.single-product__banners {
  padding: 35px 0;
}

.single-product__banner:not(:last-child) {
  margin-bottom: 35px;
}

.single-product__banner img {
  display: block;
  height: auto;
}

.banner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.banner__logo {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.banner__text {
  margin-top: 10px;
  text-align: center;
  width: 100%;

  a {
    color: $primary-color;
    font-size: 24px;
  }
}

.single-product__banner .banner__text a {
  text-decoration: none;
}

@media screen and (max-width: $screen-tablet - 1px) {
  .banner__logo > .logo__image {
    width: 73%;
  }

  .banner__logo > .logo__emblem {
    width: 23%;
  }
}

@media screen and (min-width: $screen-medium) {
  .banner {
    flex-direction: row;
  }

  .products__banner .banner {
    justify-content: center;
  }

  .banner__logo {
    justify-content: flex-start;
    width: 50%;
  }

  .banner__text {
    margin-top: 0;
    text-align: left;
    width: 48%;
  }
}