.account .title.block-collapsible-nav-title {
    display: none;
}

.account .content.block-collapsible-nav-content {
    margin-top: 25px;
}

.customer-account-edit .field.choice:last-child {
    display: none;
}

.account.customer-account-edit .block-content {
    flex-direction: column;
}

.account {
    .block {
        margin-bottom: 21px;
    }

    .block-title {
        font-family: $secondary-font;
        font-size: 26px;
    }

    .block-content {
        display: flex;

        @media screen and (max-width: $screen-tablet) {
            display: block;
        }

        justify-content: space-between;
    }

    .box {
        background-color: $light-background;
        color: $text-color;
        padding: 15px 10px;
        margin-bottom: 2%;
        width: 49%;
        @media screen and (max-width: $screen-tablet) {
            width: 100%;
        }

        &.full {
            width: 100%;
        }
    }

    .table-wrapper.orders-recent,
    .table-order-items {
        width: 100%;
    }

    .table-order-items tr {
        vertical-align: top;
    }


    .order-items .col.subtotal,
    .order-details-items tfoot .amount,
    .order-details-items tfoot .mark {
        @media screen and (max-width: $screen-tablet) {
            padding: 5px 10px;
        }
        @media screen and (min-width: $screen-small) {
            text-align: right;
        }
    }

    th.col.price {
        font-weight: bold;
    }

    .table-order-items .col {
        text-align: left;

        @media screen and (min-width: $screen-small) {
            &.actions {
                text-align: right;
            }
        }
    }

    .col.actions a:not(:nth-last-child(1)) {
        padding-right: 5px;
    }

    .box-actions > *:first-child {
        margin-right: 6px !important;
    }

    .action:not(.button--circle) span {
        font-size: 14px;
    }

    .price {
        font-size: 14px;
        line-height: 1.5;
        font-weight: normal;
    }

    ul.items-qty {
        list-style: none;
    }

    /* Add by sku */
    .form-addbysku .fields {
        display: flex;
        justify-content: space-between;
    }

    .form-addbysku .fields .field {
        display: inline-block;
    }

    .form-addbysku .field.qty input {
        max-width: 100%;
    }

    .form-addbysku .actions-toolbar {
        margin-bottom: 15px;
    }

    .form-addbysku p {
        margin-bottom: 0;
    }

    .form-addbysku input#customer_sku_csv {
        display: inline-block;
        width: 65%;
        height: 56px;
    }

    .fields.additional.deletableItem .actions-toolbar {
        width: 10%;
        @media screen and (max-width: $screen-tablet) {
            padding-right: 10px;
        }
    }

    .form-addbysku .box.upload.skus .control {
        display: flex;
        justify-content: space-between;
    }

    .form-addbysku .box.upload.skus .actions-toolbar {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: bottom;
    }

    /* My orders menu */
    ul.items.order-links li {
        display: inline-block;
        padding-right: 15px;
    }

    /* Tables */
    @media screen and (max-width: $screen-tablet) {
        .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > thead > tr > th {
            display: none;
        }

        .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) tbody > tr > td:first-child {
            padding-top: 15px;
        }

        .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr td[data-th]:before,
        .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr th[data-th]:before {
            padding-right: 10px;
            content: attr(data-th) ': ';
            display: inline-block;
            color: #111;
            font-weight: 700;
        }

        .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr td,
        .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr th {
            border-bottom: none;
            display: block;
            padding: 5px 10px;
        }

        .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) tbody > tr:not(:last-child) > td:last-child {
            border-bottom: 1px solid #ccc;
            padding-bottom: 15px;
        }

        .content__sidebar {
            order: 0;
        }

        .content__sidebar .content {
            margin-top: 0;
        }

        .content__main {
            order: 1;
        }
    }
}

.block-collapsible-nav-content .items {
    list-style: none;
}

.block-collapsible-nav-content .items > .item {
    border-left: 5px solid transparent;
    margin-left: 0;
    padding: 2px 0 2px 10px;

    &.current {
        border-color: $secondary-color;
    }

    a {
        color: $text-color-dark;
        text-decoration: none;
    }
}

.form-newsletter-manage .fieldset .field label {
    margin-top: 10px;
    color: $text-color;
}

.form-newsletter-manage .fieldset .field.no-label label {
    margin: 0;
    color: #003da6;
}
