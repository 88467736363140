a.phonenumber {
  font-size: 19px;
  color: $primary-color;
  text-decoration: none;
}

a.phonenumber::before {
  font-family: $icon-font;
  content: '\f095';
  font-weight: normal;
}