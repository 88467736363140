.container {
  display: block;
  margin: 0 auto;
  width: $container-width;
}

.content__columns {
  display: flex;
  flex-direction: column;
}

.content__main:only-child {
  width: 100%;
}

.content.container {
  display: block;
}

@media screen and (min-width: $screen-tablet) {
  .container {
    width: $container-width-tablet;
  }

  .content__columns {
    flex-direction: row;
    justify-content: space-between;
  }

  .content__sidebar {
    order: 1;
    width: 32.5%;
  }

  .content__main {
    order: 2;
    width: 65.5%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: $screen-small - 1px) {
  .content {
    margin-top: 155px;
  }

  .catalog-category-view .content {
    margin-top: 127px;
  }
}

@media screen and (min-width: $screen-small) {
  .container {
    width: $container-width-small;
  }
}

@media screen and (min-width: $screen-medium) {
  .container {
    width: $container-width-medium;
  }

  .content__sidebar {
    width: 19%;
  }

  .content__main {
    width: 79%;
  }
}

@media screen and (min-width: $screen-large) {
  .container {
    width: $container-width-large;
  }
}
