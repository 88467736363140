body {
    > aside.amgdprjs-bar-template {
        .modal-popup {
            top: auto;
            bottom: 0;
            width: 100%;
            padding: 15px;
            background-color: $primary-color;
            z-index: 10000 !important;
        }
    }

    .action-close {
        display: none;
    }

    .amgdprcookie-bar-container .amgdprcookie-policy {
        color: $text-color-light;
        line-height: 1.5;
        font-size: 17px;
        max-height: unset;
        max-width: unset;

        a {
            color: $text-color-light;
        }
    }

    .amgdprcookie-buttons-block {
        display: flex;
        flex-wrap: nowrap;
        gap: 10px;
        margin-top: 15px;
    }

    .amgdprcookie-button {
        margin: 0 !important;
        cursor: pointer;
        color: $text-color-light;
        padding: 10px;
        width: 100%;
        font-size: 16px;
        font-weight: bolder;
        border-radius: 0 !important;
        border: none;
    }

    .amgdprcookie-button.-allow {
        background-color: $secondary-color !important;
    }

    .amgdprcookie-button.-decline {
        background-color: #ADADAD !important;
        color: darkred !important;
    }

    .amgdprcookie-button.-settings {
        display: none !important;
        // ^ Remove this if the custom settings button is needed
        background-color: transparent !important;
        color: $text-color-light;
        order: 3;
        padding: 0 !important;
    }
}

@media only screen and (min-width: $screen-tablet) {
    body {
        .amgdprcookie-bar-container .amgdprcookie-buttons-block {
            display: flex !important;
            flex-direction: row;
        }

        .amgdprcookie-button {
            width: fit-content !important;
            flex-basis: auto !important;
        }
    }
}

@media only screen and (min-width: $screen-small) {
    body {
        .amgdprcookie-bar-block {
            display: flex;
            align-items: center;
            gap: 15px;
            justify-content: space-between;

            .amgdprcookie-policy {
                margin-bottom: 0;
            }

            .amgdprcookie-buttons-block {
                margin-top: 0;
                justify-content: center;

                .amgdprcookie-button {
                    transition: .3s;
                    white-space: nowrap;
                }

                .amgdprcookie-button.-allow,
                .amgdprcookie-button.-decline {
                    width: 100%;
                }

                .amgdprcookie-button.-settings {
                    width: 100%;
                }

                .amgdprcookie-button.-allow:hover {
                    background-color: $secondary-color-darker !important;
                }

                .amgdprcookie-button.-decline:hover {
                    background-color: #7A7A7A !important;
                }

                .amgdprcookie-button.-settings:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
