.home-blocks {
  margin-top: 25px;
}

.home-blocks__top > .home-blocks__banner {
  margin-bottom: 25px;
}

.home-blocks img {
  display: block;
  height: auto;
  max-width: 100%;
}

.home-blocks__logos {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0;

  & > img {
    display: block;
    margin: 2% 1% 0 1%;
    max-width: 80px;
  }
}

.home-blocks__row {
  padding-bottom: 50px;
}

.home-blocks__column:not(:last-child) {
  margin-bottom: 25px;
}

.home-blocks__banner.home-blocks__column {
    .pagebuilder-mobile-hidden {
        display: none;
    }
}

@media screen and (min-width: $screen-tablet) {
  .home-blocks__text--2col {
    column-count: 2;
  }

  .home-blocks__row {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
  }

  .home-blocks__column {
    width: 49%;
  }

    .home-blocks__banner.home-blocks__column {
        .pagebuilder-mobile-hidden {
            display: block;
        }
        .pagebuilder-mobile-only {
            display: none;
        }
    }
}
