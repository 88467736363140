.page-menu {
  display: none;
}

@media screen and (min-width: $screen-small) {
  .page-menu {
    display: block;
    font-size: 12px;
  }

  .page-menu ul {
    align-items: center;
    display: flex;
    list-style: none;
  }

  .page-menu li {
    color: $primary-color;
    margin-left: 0;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  .page-menu a {
    color: $primary-color;
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: $screen-medium) {
  .page-menu {
    font-size: 14px;
  }

  .page-menu li:not(:last-child) {
    margin-right: 30px;
  }
}
