.search.results {

    .sorter-label {
        font-size: 16px;
        display: block;
        font-weight: bold;
        color: $text-color-dark;
    }

    select#sorter.sorter-options {
        background: $light-grey;
        color: $text-color-dark;
        border: 1px solid $dark-grey;
        border-radius: 4px;
    }

    dl.block {
        margin: 20px 0;

        .title {
            font-size: 16px;
            font-weight: bold;
        }

        .item {
            background: $light-grey;
            border: 1px solid $dark-grey;
            border-radius: 4px;
            display: inline-block;
            padding: 5px;

            a {
                color: $text-color-dark;
                text-decoration: none;
            }
        }
    }
}
