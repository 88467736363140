.logo__image {
  max-width: 250px;
}

.logo__emblem {
  height: auto;
  margin-left: 18px;
  max-width: 100px;
}

@media screen and (min-width: $screen-small) {
  .logo__link {
    align-items: center;
    display: flex;
    text-decoration: none;
  }
}

@media screen and (min-width: $screen-medium) {
  .logo__image {
    max-width: 100%;
  }

  .logo__emblem {
    max-width: 123px;
  }
}
