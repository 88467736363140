.home-blocks__blogs {
    .post-list-wrapper {
        .title {
            margin-bottom: 15px;
            font-size: 24px;
        }
    }

    .post-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: auto;
        gap: 15px;

        .post-holder {
            width: 100% !important;
            margin: 0 !important;

            .post-content {
                background-color: #fff;
                padding: 15px;
                height: 100%;

                .post-description {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }

                .post-title .post-item-link {
                    color: $text-color;
                    font-weight: 600;
                    font-size: 18px;
                    text-decoration: none;
                    margin-bottom: 10px;
                    display: block;
                }

                .post-text-hld {
                    margin-bottom: 15px;
                    min-height: auto;
                }

                .post-read-more {
                    text-decoration: none;
                    text-transform: uppercase;
                    color: #fff;
                    background-color: $secondary-color;
                    padding: 10px;
                    font-weight: 700;
                    font-size: 18px;
                    width: 100%;
                    display: block;
                    text-align: center;
                    margin-top: auto;

                    &:hover {
                        background-color: $primary-color;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-tablet) {
    .home-blocks__blogs {
        .post-list-wrapper {
            .title {
                font-size: 20px;
            }
        }

        .post-list {
            grid-template-columns: 1fr;
        }
    }
}
