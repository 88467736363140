body.checkout-cart-index {
  background-color: #fff;
}

.checkout-cart-index .content__main {
  margin-left: auto;
}

.page-title--cart {
  color: $primary-color;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
}

.cart {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
}

.cart__summary {
  width: 100%;
}

.cart-form {
  border-collapse: collapse;
  width: 100%;
}

.cart-form__row {
  border-bottom: 1px solid $text-support-color;

  &:first-child {
    border-top: 1px solid $text-support-color;
  }
}

.cart-form__item {
  padding: 15px 20px;
}

.cart-form__item > .action-delete {
  color: $text-support-color;;

  &:hover {
    color: $primary-color;
  }
}

.cart-form__link {
  color: $cart-item-color;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.cart-form input[type="number"] {
    margin-bottom: 0;
}

.cart__actions {
  padding: 15px 0;
}

.cart__actions .cart__action.action.clear {
  display: none;
}

.cart__action {
  background-color: transparent;
  border: none;
  color: $cart-item-color;
  cursor: pointer;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
      background-color: transparent !important;
    text-decoration: underline;
  }
}

.cart__text {
  align-self: flex-end;
  width: 48.5%;
}

.cart__text .cart__notice {
  margin-bottom: 15px;
}

.cart__totals {
  margin-left: auto;
  width: 40%;
}

.cart-totals .table {
  border-collapse: collapse;
  line-height: 1;
  margin-bottom: 45px;
  width: 100%;
}

.cart-totals .table tbody {
  display: flex;
  flex-direction: column;
}

.cart-totals .table tr {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;

  &:not(:first-child) {
    padding-top: 20px;
  }
}

.cart-totals .table .grand.totals.excl {
  order: 10;

  strong {
    font-weight: 400;
  }
}

.cart-totals .table .totals-tax {
  order: 11;
}

.cart-totals .table .grand.totals.incl {
  order: 12;

  .mark {
    color: $primary-color;
  }

  .price {
    color: $primary-color;
    font-size: 30px;
    font-weight: 700;
  }
}

.cart-totals .mark {
  font-weight: 400;
}

.cart-totals .price {
  color: $text-color;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
}

.checkout-methods-items {
  list-style: none;
}

.button.checkout {
  text-align: center;
  width: 100%;
}

.button.checkout > span {
  position: relative;

  &:before {
    content: url('../images/sparkle.png');
    left: -34px;
    position: absolute;
    top: -10px;
  }
}

.cart-empty {
  margin-top: 20px;
}

.cart-empty > p:first-of-type {
  color: $primary-color;
  font-size: 22px;
  font-weight: 700;
}

td.cart-form__item.cart-form__item-price:before,
td.cart-form__item.cart-form__item-unit-price:before {
  content: '€';
}

.cart-form__item-actions {
    padding-left: 0;
}

.cart-form__item-price {
    padding-right: 0;
}

.cart-form__item-unit-price,
.cart-form__item-price {
    white-space: nowrap;
}

@media screen and (max-width: $screen-tablet - 1px) {
  .cart {
    flex-direction: column;
  }

  .cart__summary {
    order: 1;
  }

  .cart__totals {
    order: 2;
    width: 100%;
  }

  .cart-totals .price {
      font-size: 16px;
  }

  .cart-form {
    display: block;

    tbody,
    .cart-form__row {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .cart-form__item-actions {
    padding: 15px 0;
  }

  .cart-form__item-name {
    order: 1;
    padding: 15px 0;
    width: 100%;
  }

  .cart-form__item-action {
    order: 2;
  }

  .cart-form__item-image {
    order: 3;
    padding: 15px 10px 15px 0;
  }

  .cart-form__item-image img {
      width: 150px;
  }

  .cart-form__item-unit-price {
    margin-left: auto;
    padding: 15px 10px;
    order: 4;
  }

  .cart-form__item-amount {
    order: 5;
    padding: 15px 0;
  }

  .cart-form__item-price {
    order: 6;
    padding-left: 10px;
  }

  .cart__actions {
    text-align: center;
  }

  .cart__action {
    display: block;
    margin: 0;
    padding: 10px 15px;
    width: 100%;
  }

  .cart__text {
    margin-top: 30px;
    order: 3;
    width: 100%;
  }

  .cart-empty {
    margin: 20px 0;
  }
}

.cart-summary > #block-shipping {
  display: none;
}

/*Cart Cross-sells */
.checkout-cart-index .related-products {
    .product__bottom > form {
        display: flex;
        justify-content: space-between;
    }

    .add-to-cart__input {
        .product__qty {
           margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 580px) {
    .cart-form__item-image img {
        width: 50px;
    }
}

@media screen and (min-width: $screen-tablet) {
  .cart-empty {
    min-height: calc(100vh - 657px);
  }
}

@media screen and (min-width: $screen-small) {
  .cart-empty {
    min-height: calc(100vh - 595px);
  }
}

@media screen and (min-width: $screen-medium) {
  .cart-empty {
    min-height: calc(100vh - 624px);
  }
}
