.service-block {
  background-image: url(../images/service-blob.svg);
  background-repeat: no-repeat;
  height: 177px;
  margin: 30px 0;
  padding: 30px 15px;
  position: relative;
  width: 180px;

  &:after {
    content: url(../images/sparkle.png);
    left: 75px;
    position: absolute;
    top: 18px;
  }
}

.service-block h4 {
  color: #fff;
  font-family: $secondary-font;
  font-size: 24px;
  margin-bottom: 15px;
}

.service-block a {
  color: #fff;
  display: block;
  font-size: 16px;
  line-height: 1.8;
  text-decoration: none;
}

@media screen and (min-width: $screen-tablet) {
  .service-block {
    margin: 0;
  }
}