.header-top-bar {
    background-color: $header-top-bar-background-mobile;
    padding-bottom: 7px;
}

.header-top-bar__items {
    padding-top: 7px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.header-top-bar__item {
    margin-left: 20px;
    z-index: 15;
    list-style: none;

    &:first-child {
        margin-left: 0;
    }

    &.phone {
        display: none;
    }
}

.header-top-bar__arrow {
    color: $primary-color;
    cursor: pointer;

    &:after {
        content: '\f107';
        font-family: $icon-font;
    }

    &:hover {
        text-decoration: underline;
    }
}

.header-top-bar__dropdown {
    background-color: #FFFFFF;
    border-top: none;
    box-sizing: border-box;
    display: none;
    padding: 40px;
    position: absolute;
    top: 100%;
    z-index: 10;
    width: 500px;
    right: 0;
    top: -7px;
    box-shadow: $box-shadow-default;
}


.header-top-bar__account-links > a {
    display: block;
    padding-bottom: 5px;
    padding-top: 5px;

    &:first-child {
        padding-top: 0;
    }

    &:last-of-type {
        padding-bottom: 0;
    }
}

.header-top-bar__account p {
    white-space: normal;
}

.header-top-bar__account p {
    white-space: normal;
    margin: 15px 0 15px 0;
}

.header-top-bar__account .secondary {
    white-space: normal;
    margin: 0 0 15px 0;
}

.header-top-bar__login h4 {
    font-size: 20px;
    color: $primary-color;
    padding-bottom: 12px;
    border-bottom: 1px solid $border-color-light;
}

.header-top-bar__login .action.remind a {
    color: $text-color-dark;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.header-toolbar__dropdown.header-toolbar__account {
    padding: 0;
}

.header-toolbar__dropdown .block {
    margin-bottom: 0;
    width: 190px;
}

.header-toolbar__dropdown .form-login .fieldset > .field {
    margin-bottom: 10px;
}

.header-toolbar__dropdown .form-login .fieldset > .field > .label {
    padding: 0;
    text-align: left;
    width: 100%;
}

.header-toolbar__dropdown .form-login .fieldset > .field > .control {
    width: 100%;
}

.header-toolbar__dropdown .form-login .fieldset > .actions-toolbar > .primary {
    width: 100%;
}

.header-toolbar__dropdown .form-login .fieldset > .actions-toolbar > .primary > .action {
    background-color: $tertiary-color;
    border: none;
    display: block;
    line-height: 1;
    margin: 0;
    padding: 6px 10px;
    width: 100%;
}

.header-top-bar__account .actions-toolbar .button--text {
    text-decoration: underline;
}

.header-middle-bar {
    background-color: $header-middle-bar-background;
    padding: 10px 0;
}

.header-middle-bar__items {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

#close-header-top-bar-account {
    cursor: pointer;
    display: block;
    font-size: 20px;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 0;

    & > i {
        display: block;
    }
}

.header-top__notice {
    height: 40px;
    padding: 10px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: $primary-color;
    background: $header-top-bar-background;
}

@media screen and (max-width: 500px) {
    .header-top-bar__item.phone {
        display: block;

        a.phonenumber {
            font-size: 0;

            &:before {
                color: $secondary-color;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }

    .header-middle-bar__item {
        width: 100%;

        &:last-child {
            display: none;
        }
    }

    .header-middle-bar__item .search-form {
        width: 100%;
    }
}

@media screen and (max-width: $screen-tablet - 1px) {
    .header-top-bar__dropdown {
        width: 75%;
    }

    .header-middle-bar__items {
        position: relative;
    }
}

@media screen and (max-width: 480px) {
    .header-top-bar__dropdown {
        width: 100%;
    }
}

@media screen and (min-width: 501px) and (max-width: $screen-small - 1px) {
    .header-middle-bar__item {
        width: 48.5%;
    }
}

@media screen and (max-width: $screen-small - 1px) {
    .header {
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 10;
    }

    .header-top-bar {
        position: relative;
    }

    .header-top-bar__arrow {
        color: $secondary-color;
        font-size: 18px;

        &:after {
            content: none;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .header-top-bar__arrow.show-mobile {
        display: block;
    }

    .header-top-bar__item.user .header-top-bar__arrow.show-mobile {
        position: absolute;
        right: 75px;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media screen and (min-width: $screen-small) {
    .header-top-bar {
        background-color: $header-top-bar-background;
    }

    .header-top-bar__items {
        justify-content: flex-end;
    }

    .header-top-bar__item {
        position: relative;
        z-index: 30;
    }

    .header-bottom-bar {
        background-color: $header-bottom-bar-background;
        position: relative;
        z-index: 25;
    }

    .header-bottom-bar__items {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .header-bottom-bar__item {
        width: 100%;
    }

    .my-account__link {
        color: $primary-color;
        display: inline-block;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .header-top__notice {
        height: unset;
        padding: 5px;
        color: #fff;
        background: $secondary-color;
    }
}

@media screen and (min-width: $screen-small) and (max-width: $screen-medium - 1px) {
    .my-account__link {
        font-size: 12px;
    }
}

@media screen and (max-width: $screen-medium - 1px) {
    .header-top-bar__arrow {
        font-size: 12px;
    }
}

@media screen and (min-width: $screen-medium) {
    .header-top-bar__item {
        margin-left: 30px;
    }
}
