.breadcrumbs {
  padding: 10px 0;
}

.breadcrumbs__items {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.breadcrumbs__item {
  color: $quaternary-color;
  font-size: 13px;
  margin: 0;
  padding: 0 10px 0 17px;
  position: relative;

  &:not(:last-child):after {
    content: '>';
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.breadcrumbs__link {
  color: $quaternary-color;
  display: block;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: $screen-tablet) {
  .breadcrumbs {
    padding-left: 34.5%;
  }
}

@media screen and (min-width: $screen-medium) {
  .breadcrumbs {
    padding-left: 21%;
  }
}