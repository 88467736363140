.owl-carousel.products {
    overflow: hidden;

    .owl-stage {
        display: flex;
        padding-left: 0 !important;
    }

    .product {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border: 1px solid #b8b8b8;
        margin-bottom: 2%;
        padding: 15px 15px 30px;
        transition: border-color .3s;
    }
}

.owl-carousel .owl-dots {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 25px 0;
}

.owl-carousel button.owl-dot {
    border-radius: 50%;
    border: 1px solid #b8b8b8;
    height: 30px;
    width: 30px;
    background: #fff;
    margin-right: 10px;
}

.owl-carousel button.owl-dot.active {
    background: $secondary-color;
}

.owl-carousel .owl-nav {
    display: none;
}

