.customer-account-create .legend {
    padding-top: 15px;
}

.customer-account-create .legend span {
    font-weight: bold;
}

.customer-account-create .field .comment,
.customer-account-create #password-strength-meter-container {
    padding: 0;
    font-size: 14px;
    margin-bottom: 15px;
}

.customer-account-create button#euvat_action_validate_taxvat {
    margin-top: 0;
}

.customer-account-create div.euvat-taxvat-container #euvat_validation_message {
    padding-top: 0;
}

.customer-account-create div.euvat-message {
    margin-top: 0;
}

.field-taxvat ~ .field-taxvat {
    display: none;
}

.customer-account-create div.euvat-taxvat-container input.input-text {
    width: 100%;
}

.customer-account-create button#euvat_action_validate_taxvat {
    margin-bottom: 10px;
}

@media screen and (min-width: 565px) {
    .customer-account-create div.euvat-taxvat-container input.input-text {
        width: 75%;
    }

    .customer-account-create button#euvat_action_validate_taxvat {
        margin-bottom: 0;
    }
}
