.cms-no-route .page-title {
  margin-bottom: 20px;
}

.cms-no-route dl:not(:last-child),
.cms-no-route dd:not(:last-child) {
  margin-bottom: 20px;
}

.cms-no-route dt:not(:last-child) {
  font-size: 21px;
  margin-bottom: 5px
}

@media screen and (min-width: $screen-tablet) {
  .cms-no-route #maincontent {
    min-height: calc(100vh - 617px);
  }

  .cms-no-route .page-title {
    padding-left: 34.5%;
  }
}

@media screen and (min-width: $screen-small) {
  .cms-no-route #maincontent {
    min-height: calc(100vh - 575px);
  }
}

@media screen and (min-width: $screen-medium) {
  .cms-no-route #maincontent {
    min-height: calc(100vh - 604px);
  }

  .cms-no-route .page-title {
    padding-left: 21%;
  }
}