.search-form {
    display: flex;
    max-width: 100%;
    width: 420px;
}

.search-form__input-wrap {
    flex-grow: 1;
}

.search-form__input {
    background-color: #EEF0F3;
    border: none;
    color: $primary-color;
    font-size: 13px;
    padding: 18px 20px;
    width: 100%;

    &::placeholder {
        color: $text-color-dark;
        opacity: 1;
    }

    &:focus {
        outline: none;
    }
}

.search-form__button {
    background-color: #899BA9;
    border: none;
    color: $text-color-light;
    cursor: pointer;
    font-size: 13px;
    padding: 18px 20px;
    height: 51px;

    &:focus {
        outline: none;
    }
}

#search_autocomplete {
    display: none !important;
}

.search-autocomplete:not(:empty) {
    background: #fff;
    padding: 10px 20px;
    width: 100% !important;
    z-index: 30;
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
}

.search-autocomplete .autocomplete-list:first-child {
    display: none;
}

.search-autocomplete .autocomplete-list > dt {
    display: none;
}

.search-autocomplete .autocomplete-list > dd {
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
    }

    &:last-child:has(.product-image-box) {
        margin-bottom: 30px;
    }

    &:has(.category-mini-crumb) {
        display: block;
    }

    .product-image-box {
        flex-shrink: 0;
    }

    .qs-option-name {
        font-weight: bold;
    }

    .product-item {
        flex-grow: 1;
        margin-left: 10px;

        .f-fix {
            display: flex;
            justify-content: space-between;
        }

        .product-name {
            padding-right: 10px;
        }
    }

    .price__incl-vat {
        display: none;
    }
}

.search-autocomplete > ul > li {
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    & > .amount {
        color: $primary-color;
    }
}

.mst-searchautocomplete__close,
.mst-searchautocomplete__index-title {
    display: none;
}

.mst-searchautocomplete__wrapper {
    position: absolute;
    background-color: #FFF;
    width: 100%;
    z-index: 30;
}

.mst-searchautocomplete__index ul {
    list-style: none;
    padding: 20px 10px 0 20px;
    margin-top: 10px;
}

.mst-searchautocomplete__index li {
    clear: both;
    display: flex;
}

.mst-searchautocomplete__index li img {
    display: inline-block;
    padding: 0 10px 10px 0;
    clear: both;
    width: 80px;
}

.mst-searchautocomplete__index li .meta {
    display: flex;
    justify-content: space-between;
    vertical-align: top;
    width: 100%;
    padding: 10px 0;
}

.mst-searchautocomplete__index li .title {
    display: inline-block;
    vertical-align: top;
}

.search-autocomplete .product-name {
    color: $primary-color;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;

    &:hover {
        text-decoration: underline;
    }
}

.search-autocomplete .price {
    line-height: 1;
    padding-left: 10px;
}

.mst-searchautocomplete__empty-result {
    display: none;
}

.mst-searchautocomplete__show-all {
    clear: both;
    padding: 0 20px 10px 20px;
}

.mst-searchautocomplete__item .price__incl-vat {
    display: inline-block;
    font-size: 12px;
}

.mst-searchautocomplete__autocomplete {
    display: none;

    &._active {
        display: block;
    }
}

.search-result-pills-holder {
    margin-top: 7px;
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
}

@media screen and (min-width: $screen-tablet) {
    .search-form {
        position: relative;
    }
}
