.page.messages .message {
  font-family: $secondary-font;

  & > div:not(:empty) {
    margin: 10px 0;
    padding: 10px 15px;
  }
}

.message-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.message-notice {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.message-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.mage-error {
  margin-bottom: 15px;
  color: #FF0000;
}