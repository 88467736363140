/* Base colors
================================================== */

$primary-color: #003da6;
$secondary-color: #8cc63f;
$secondary-color-darker: #567B24;
$tertiary-color: #bec7d0;
$quaternary-color: #899ba9;

$body-background: #f7f8f9;
$light-background: #FFFFFF;

$border-color: #9b8f84;
$border-color-light: #ccc;

$box-shadow-default: 0 0 5px rgba(0, 0, 0, 0.2);


/* Text colors
================================================== */

$text-color: #383838;
$text-color-light: #fff;
$text-color-dark: #000;
$text-support-color: #838383;


/* Header colors
================================================== */

$header-top-bar-background: #bec7d0;
$header-top-bar-background-mobile: $primary-color;
$header-middle-bar-background: #fff;
$header-bottom-bar-background: $primary-color;


/* Navigation colors
================================================== */

$mega-menu-background: #fff;
$mega-menu-overlay: rgba(137, 155, 169, 0.75);


/* Footer colors
================================================== */

$footer-top-bar-background: #fff;
$footer-middle-bar-background: #bec7d0;
$footer-bottom-bar-background: #fff;


/* Form
================================================== */

$input-border: 1px solid #899ba9;
$input-font-size: 13px;
$input-padding: 15px;
$input-icon-padding: 15px 15px 15px 45px;

/* Cart
================================================== */

$cart-item-color: #005eb8;

/* Search
================================================== */

$light-grey: lightgrey;
$dark-grey: darkgrey;




/* Fonts
================================================== */

$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Fira Sans Condensed', sans-serif;
$icon-font: 'Font Awesome 5 Pro';


/* Responsive breakpoints and container widths
================================================== */

$container-width: 95%;
$container-width-tablet: 750px;
$container-width-small: 970px;
$container-width-medium: 1170px;
$container-width-large: 1280px;

$screen-tablet: 768px;
$screen-small: 992px;
$screen-medium: 1200px;
$screen-large: 1400px;
