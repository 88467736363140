.modal-popup {
  background-color: #fff;
  display: none;
  left: 50%;
  padding: 30px;
  position: fixed;
  top: 10vh;
  transform: translateX(-50%);

  &._show {
    display: block;
    z-index: 50;
  }
}

.modals-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  &:focus {
    outline: none !important;
  }
}

.modals-wrapper:has(.modal-popup._show) .modals-overlay {
  display: block;
  z-index: 45;
}

.popup-authentication .modal-header {
    display: none;
}

@media screen and (max-width: $screen-tablet - 1px) {
    .modal-popup {
        width: 95%;
    }
}
