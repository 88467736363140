.toolbar__items {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: $primary-color;
}

.toolbar__item {
  flex-basis: 48.5%;
  flex-grow: 0;
}

/* Pagination */
.toolbar__item.pagination {
  text-align: right;
}

.pages__items li {
  display: inline-block;
  list-style: none;
  text-align: center;
  vertical-align: bottom;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: border 0.3s ease;
  margin: 0;

  &:hover {
    border: 1px solid rgba($border-color, .25) !important;
  }
}

.pages__items li a,
.pages__items li.current > span {
  display: block;
  padding: 7px 14px;
  color: $primary-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.pages__items li.current {
  background-color: rgba($border-color, .25);
}

.pages__items li.previous,
.pages__items li.next {
  border: 1px solid rgba($border-color, .5);
  font-size: 18px;

  a {
    padding: 5px 14px 3px 14px;
  }
}

/* Sorter */
.toolbar__item.sorter {
  text-align: left;
}

.toolbar__item.sorter .sorter-label {
  font-size: 14px;
  padding-right: 7px;
}

.toolbar__item.sorter select#sorter {
  padding: 9px;
  font-size: 14px;
  background-color: transparent;
  border-color: $border-color-light;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  width: 200px;
  color: $primary-color;
  outline: none;
}

.toolbar__item.sorter .select-wrap {
  position: relative;
  display: inline-block;

  &:after {
    content: "\f078 ";
    font-family: $icon-font;
    font-size: 10px;
    font-weight: bold;
    color: $primary-color;
    position: absolute;
    right: 13px;
    top: 12px;
    z-index: 1;
    pointer-events: none;
  }
}

.search.results #amasty-shopby-product-list {
    display: flex;
    flex-direction: column;
}

.search.results #amasty-shopby-product-list .mst-search__misc-recommendations {
    order: 1;
    margin: 10px 0;
}

.search.results #amasty-shopby-product-list .toolbar__items {
    order: 2;
}

.search.results #amasty-shopby-product-list .products {
    order: 3;
}

.search.results #amasty-shopby-product-list .toolbar.toolbar-bottom {
    order: 4;
}

@media screen and (max-width: $screen-tablet - 1px) {
  .toolbar__item.pagination {
    display: none;
  }

  .toolbar-bottom {
    display: block;
  }

  .toolbar-bottom .toolbar__item.sorter {
    display: none;
  }

  .toolbar-bottom .toolbar__item.pagination {
    display: block;
    flex-basis: 100%;
    text-align: center;
    width: 100%;
  }

  .sorter-label {
    display: none;
  }
}

@media screen and (max-width: $screen-medium - 1px) {
  .toolbar__items {
    justify-content: space-between;
  }

  .toolbar__item {
    &.sorter {
      align-items: center;
      display: flex;
    }
  }

  .toolbar__item.sorter select#sorter {
    color: $text-color;
    width: 100%;
  }

  .sorter-label {
    white-space: nowrap;
  }

  .toolbar__item.sorter {
    color: $text-color;
  }

  .toolbar__item.sorter .select-wrap {
    flex-grow: 1;

    &:after {
      color: $text-color;
      font-size: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media screen and (min-width: $screen-medium) {
  .toolbar__item {
    width: 32.5%;
    flex-grow: 0;

    &:not(:last-child) {
      margin-right: 2%;
    }
  }
}
