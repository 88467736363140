body.blog-page #maincontent {
  .post-title a {
    text-decoration: none;
    color: $text-color;
    font-weight: 600;
  }

  .post-description {
    margin-bottom: 15px;
  }

  .post-read-more a {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    background-color: $secondary-color;
    padding: 10px;
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    display: block;
    text-align: center;

    &:hover {
      background-color: $primary-color;
    }
  }

  .sidebar__additional {
    margin-bottom: 30px;

    ul.accordion li {
      padding: 5px 0;

      a {
        color: $primary-color;
        text-decoration: none;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .block.block-archive {
      margin-top: 15px;

      .block-content {
        .archive-item-link {
          color: $primary-color;
          text-decoration: none;
          font-weight: 600;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .post-list.modern {
    .post-item:first-of-type {
      .post-read-more a {
        width: 200px;
      }
    }
  }

  .post-list.modern {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    gap: 15px;

    .post-item {
      background-color: #fff;
      padding: 15px;
      margin: 0;

      .post-container {
        flex-direction: column;
        height: 100%;

        .post-image {
          width: 100%;
          margin-bottom: 10px;
          flex: auto;

          .image-wrap {
            padding: 0;
          }
        }

        .post-bg-image {
          height: 150px;
          background-size: cover;
          background-position: center center;
        }

        .post-info {
          display: flex;
          flex-direction: column;
          flex: auto;
          height: 100%;
          width: 100%;
          max-width: unset;
          padding: 0;

          .post-read-more {
            margin-top: auto;
          }
        }
      }
    }

    .post-item:first-of-type {
      grid-column: span 3;

      .post-bg-image {
        height: 300px;
        background-size: cover;
        background-position: center center;
      }
    }
  }
}

@media only screen and (max-width: $screen-tablet) {
  body.blog-page #maincontent {
    .post-list.modern .post-description {
      display: block;
    }

    .post-list.modern {
      grid-template-columns: repeat(2, 1fr);

      .post-item:first-of-type {
        grid-column: span 2;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  body.blog-page #maincontent {
    .post-title a {
      font-size: 18px !important;
    }
  }
}

@media only screen and (max-width: 576px) {
  body.blog-page #maincontent {
    .post-list.modern {
      grid-template-columns: 1fr;

      .post-item:first-of-type {
        grid-column: span 1;

        .post-bg-image {
          height: 150px;
        }
      }
    }

    .post-list.modern {
      .post-item:first-of-type {
        .post-read-more a {
          width: 100%;
        }
      }
    }
  }
}
