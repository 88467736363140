.single-product__wrap {
    background-color: #fff;
    box-shadow: $box-shadow-default;
    padding: 25px 35px;
}

.single-product__primary {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.single-product__media {
    margin-bottom: 25px;
    width: 100%;
}

.page-title--product {
    color: $primary-color;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
}

.single-product__info {
    width: 100%;
}

.single-product__title-price-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    line-height: 1.2;
    margin-bottom: 10px;
}

.single-product__price {
    text-align: right;
}

.price {
    color: $text-color-dark;
    display: block;
    font-family: $secondary-font;
    font-size: 22px;
    font-weight: bold;
}

.price--large {
    font-size: 45px;
}

.price__incl-vat {
    color: $text-support-color;
}

.price__old--line-through {
    color: $text-support-color;
    text-decoration: line-through;
}

.single-product__short-description {
    margin-bottom: 15px;
}

.single-product__short-description li {
    margin-left: 20px;
}

.single-product__sku {
    margin-bottom: 35px;
}

.single-product__add-to-cart {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
}

.single-product__stock {
    margin-top: 10px;
    text-align: right;
}

.single-product__stock .in-stock {
    color: $secondary-color;
    font-weight: bold;
}

.single-product__stock .out-of-stock {
    color: $primary-color;
    font-weight: bold;
}

.add-to-cart__input > .label {
    margin-right: 10px;
}

.single-product__secondary {
    margin-top: 90px;
}

.single-product__related {
    margin-top: 25px;
}

.single-product__tabs:after {
    clear: both;
    content: '';
    display: block;
}

.single-product__tabs h3 {
    color: $primary-color;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.tab-title {
    width: 25%;
    text-align: center;
    display: inline-block;
    border-bottom: 1px solid transparent;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-top: 1px solid $border-color;
    float: left;
    position: relative;
    z-index: 2;

    &:last-child {
        border-right: 1px solid $border-color;
    }

    &.active {
        border-bottom: 1px solid #fff;
    }

    &:hover > a {
        font-weight: bold;
    }
}

.tab-title--2 {
    width: 50%;
}

.tab-title--3 {
    width: 33.33%;
}

.tab-title--4 {
    width: 25%;
}

.tab-title {
    a {
        color: #005eb8;
        display: block;
        padding: 12px 4px;
        font-size: 14px;
        font-weight: normal;
        text-decoration: none;

        &:focus {
            outline: none;
        }
    }

    &.active a {
        font-weight: bold;
    }
}

.single-product__tabs .content {
    border-top: 1px solid $border-color;
    display: block;
    float: right;
    margin-left: -100%;
    margin-top: 46px;
    padding-top: 20px;
    width: 100%;
}

.single-product__tabs .additional-attributes {
    text-align: left;
    line-height: 30px;
    border-collapse: collapse;
}

.single-product__tabs .additional-attributes tr th {
    width: 200px;
}

.related-products__title {
    color: $primary-color;
    font-size: 21px;
    margin-bottom: 25px;
}

.single-product__request-quote {
    margin-bottom: 20px;

    a {
        color: $primary-color;
        display: block;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.single-product__add-to-cart .input-field {
    margin-bottom: 0;
}

.am-fileline {
    align-items: center;
    display: flex;

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    & > img {
        height: 36px;
        margin-right: 10px;
        width: 30px;
    }
}

.am-filesize {
    margin-left: 5px;
}

.related-products .add-to-cart__input .product__qty {
    margin-bottom: 0;
}

.related-products .product__bottom > form {
    display: flex;
    justify-content: space-between;
}

.related-products .product__info > a {
    text-decoration: none;
    flex: 1;

    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 480px) {
    .single-product__title-price-wrap {
        flex-direction: column;
        align-items: flex-start;
    }

    .single-product__price {
        margin-top: 10px;
        text-align: left;
    }

    .single-product__add-to-cart {
        align-items: flex-end;
        flex-direction: column;
    }

    .add-to-cart__button {
        margin-top: 10px;
        width: 100%;

        .button {
            padding: 10px 15px;
            width: 100%;
        }
    }
}

@media screen and (max-width: $screen-tablet - 1px) {
    .single-product__wrap {
        padding: 25px 10px;
    }
}

@media screen and (max-width: $screen-small - 1px) {
    .tab-title {
        border: 1px solid $text-support-color;
        float: none;
        margin-top: -1px;
        padding: 0 7px;
        position: relative;
        text-align: left;
        width: 100%;

        &:after {
            content: '\f107';
            font-family: $icon-font;
            font-size: 20px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        &.active:after {
            content: '\f106';
        }
    }

    .single-product__tabs .content {
        border-color: $text-support-color;
        border-style: solid;
        border-width: 0 1px 1px;
        float: none;
        margin: 0;
        padding: 0 10px 10px;
    }

    .single-product__tabs .additional-attributes {
        display: block;
        width: 100%;

        tbody,
        tr,
        tr > th,
        tr > td {
            display: block;
            line-height: 1.5;
            width: 100%;
        }
    }
}

@media screen and (min-width: $screen-small) {
    .tab-title:not(:first-child) {
        margin-left: -1px;
    }

    .single-product__tabs .content {
        margin-right: 2px;
        width: calc(100% - 2px);
    }
}

@media screen and (min-width: $screen-medium) {
    .single-product__primary {
        flex-direction: row;
        justify-content: space-between;
    }

    .single-product__media {
        margin-bottom: 0;
        width: 48.5%;
    }

    .single-product__info {
        width: 48.5%;
    }
}
