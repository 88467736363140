.single-product__tier-prices {
  margin-bottom: 35px;
}

.tier-prices__rows {
  width: 100%;
}

.tier-prices__heading {
  color: $primary-color;
  font-weight: 400;
  text-align: right;
}

.tier-prices__item {
  text-align: right;
}