#minicart {
  width: 48px;
  z-index: 10;
}

.minicart {
  background-color: $secondary-color;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 40px;
}

.minicart__link {
  color: $primary-color;
  display: block;
  font-size: 21px;
  line-height: 1;
  padding: 12px 17px 10px 10px;
}

.minicart__inner {
  display: block;
  padding-top: 6px;
}

.minicart__counter {
  align-items: center;
  background-color: $primary-color;
  border-radius: 10px;
  color: $text-color-light;
  display: flex;
  font-size: 13px;
  font-weight: 700;
  height: 18px;
  justify-content: center;
  padding: 0 5px;
  position: absolute;
  right: 5px;
  top: 12px;
  min-width: 18px;
}

.minicart__counter--empty {
  display: none;
}

@media screen and (min-width: $screen-small) {
  #minicart {
    position: relative;
  }

  .minicart {
    bottom: auto;
    top: -18px;
  }

  .minicart__link {
    padding: 10px 17px 10px 10px;
  }

  .minicart__counter {
    top: 3px;
  }
}
