body.blog-page #maincontent {
    .breadcrumbs {
        padding-left: 0;
    }

    .content__columns {
        margin-top: 15px;
        flex-direction: row-reverse;
    }
}

@media only screen and (max-width: $screen-small) {
    body.blog-page-list #maincontent {
        .content__columns {
            flex-direction: column-reverse;
        }

        .content__main {
            width: 100%;
        }
    }
}

@media only screen and (max-width: $screen-tablet) {
    body.blog-page #maincontent {
        .content__columns {
            flex-direction: column;
        }
    }
}
