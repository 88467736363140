.control._with-tooltip {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.field:not(:last-child) .control._with-tooltip {
  margin-bottom: 15px;
}

.control._with-tooltip .input-text {
  margin-bottom: 0;
  margin-right: 5px;
  width: 89%;
}

.field-tooltip-action {
  cursor: pointer;

  &:before {
    color: #899ba9;
    content: '\f059';
    font-family: $icon-font;
    font-size: 20px;
  }

  &:hover:before,
  &._active:before {
    color: $primary-color;
  }

  &:focus {
    outline: none;
  }
}

.field-tooltip-content {
  display: none;
}

.field-tooltip._active .field-tooltip-content {
  background-color: $primary-color;
  color: $text-color-light;
  display: block;
  padding: 5px 10px;
  position: absolute;
  right: 0;
  top: calc(100% + 5px);
  width: 100%;

  &:after {
    border-color: transparent transparent $primary-color transparent;
    border-style: solid;
    border-width: 0 5px 8px 5px;
    content: '';
    position: absolute;
    right: 4px;
    top: -8px;
  }
}

@media screen and (min-width: 400px) {
  .control._with-tooltip .input-text {
    width: 91%;
  }
}

@media screen and (min-width: 400px) {
  .control._with-tooltip .input-text {
    width: 93%;
  }
}

@media screen and (min-width: $screen-tablet) {
  .control._with-tooltip .input-text {
    width: 95%;
  }
}

@media screen and (min-width: $screen-small) {
  .control._with-tooltip .input-text {
    width: 89%;
  }
}

@media screen and (min-width: $screen-medium) {
  .control._with-tooltip .input-text {
    width: 92%;
  }
}