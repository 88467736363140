body {
  color: $text-color;
  font-family: $primary-font;
  font-size: 14px;
  line-height: 1.5;
}

ul > li,
ol > li {
  margin-left: 20px;
}

p:not(:last-child) {
  margin-bottom: 25px;
}

a {
  color: $secondary-color;
}

h1.page-title {
  margin-top: 20px;
}

blockquote {
  color: $primary-color;
  font-family: $secondary-font;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;

  &:before {
    content: '"';
  }

  &:after {
    content: '"';
  }

  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: $screen-tablet) {
  blockquote {
    font-size: 45px;
  }
}

@media screen and (max-width: $screen-small - 1px) {

}

@media screen and (min-width: $screen-small) {
  blockquote {
    font-size: 62px;
  }
}

@media screen and (min-width: $screen-medium) {

}

@media screen and (min-width: $screen-large) {

}