.checkout-index-index .content,
.checkout-index-index .content__main #checkout.am-checkout {
    background-color: $body-background;
}

.checkout-index-index .content {
    margin-top: 0;
}

.checkout-index-index .content__main #checkout.am-checkout {
    padding: 0;
}

.checkout-index-index .content__main {
    width: 100%;
}

.checkout-index-index .title {
    color: $primary-color;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
}

.checkout-index-index .items-in-cart .title {
    font-size: 18px;
    position: relative;

    &:before {
        content: '\f107';
        font-family: $icon-font;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &:focus {
        outline: none;
    }
}

.checkout-index-index .items-in-cart.active .title:before {
    content: '\f106';
}

.checkout-shipping-address {
    .shipping-address-details {
        line-height: 1.8;

        .action.action-edit-address {
            margin-top: 1rem;
        }
    }

    .fieldset {
        .actions-toolbar {
            flex-direction: row !important;

            .primary {
                float: none !important;
                display: flex !important;
                gap: .5rem !important;
            }
        }
    }
}

#checkout-step-shipping_method {
    line-height: 1.8 !important;
}

.step-content.amcheckout-content {
    .amcheckout-wrapper {
        margin: .5rem 0 !important;
        display: flex !important;
        flex-direction: column !important;
        gap: .5rem !important;

        .payment-method {
            padding: .5rem !important;
            display: flex !important;
            border: 1px solid $quaternary-color !important;
            border-radius: 5px !important;

            .payment-method-title {
                display: flex !important;
                align-items: center !important;
                gap: .5rem !important;
            }

            :has(:checked) {
                border-color: $secondary-color !important;
            }
        }
    }
}

.step-title,
.checkout-index-index .table-caption {
    color: $primary-color;
    font-size: 18px;
    font-weight: 700;

    &:empty {
        display: none;
    }
}

.step-title:not(:last-child) {
    margin-bottom: 25px;
}

.checkout-index-index .table-caption {
    text-align: left;
}

.checkout-index-index .fieldset,
.checkout-index-index .field {
    border: none;
}

.checkout-index-index .authentication-wrapper {
    position: relative;
    width: 32.5%;
}

.checkout-index-index .authentication-dropdown {
    display: none;

    &._show {
        display: block;
    }
}

.checkout-index-index .table-totals {
    margin-top: 30px;
    width: 100%;

    th {
        font-weight: 400;
        text-align: left;
    }

    .grand.totals th {
        font-weight: 700;
    }

    td {
        text-align: right;
    }
}

.checkout-index-index .minicart-items {
    list-style: none;
}

.checkout-index-index .product {
    display: flex;
    padding: 0;
    width: 100%;
}

.checkout-index-index .opc-block-summary .minicart-items .product-image-container {
    margin-left: 0 !important;
}

.checkout-index-index textarea {
    width: 100%;
}

.checkout-index-index .opc-block-summary .minicart-items .product-item-details {
    flex-grow: 1;
    padding-left: 15px !important;
}

.checkout-index-index .product-item-inner {
    display: flex;
    justify-content: space-between;
}

.checkout-index-index .additional-options {
    margin: 0 !important;
    display: flex;
    flex-direction: column-reverse;
}

.checkout-index-index .additional-options {
    margin: 0 !important;
}

.checkout-index-index .price {
    font-size: 16px;
    font-weight: 400;
}

.checkout-index-index .grand.totals .price {
    font-size: 20px;
    font-weight: 700;
}

.checkout-index-index .checkout-header {
    padding: 0 !important;
}

.checkout-index-index .content__main button {
    font-size: 22px !important;
    line-height: 1 !important;
    padding: 10px 15px !important;
}

.checkout-payment-method .payment-methods .actions-toolbar .primary {
    margin-top: 30px;
    width: 100%;
}

.checkout-index-index .opc-wrapper.am-opc-wrapper .checkout-billing-address .primary {
    display: flex;
    float: none !important;
    justify-content: flex-end;
}

.checkout-index-index .opc-wrapper.am-opc-wrapper #tooltip-label.label {
    display: none;
}

.checkout-index-index .opc-wrapper.am-opc-wrapper .checkout-billing-address .actions-toolbar .action.action-cancel {
    margin: 0 0 0 15px !important;
}

.checkout-index-index .opc-wrapper.am-opc-wrapper .checkout-billing-address .actions-toolbar .action.action-update,
.checkout-index-index .opc-wrapper.am-opc-wrapper .checkout-billing-address .actions-toolbar .action.action-cancel {
    font-size: 18px !important;
}

.checkout-index-index .action-close {
    display: none;
}

.checkout-index-index .payment-option-title {
    color: $primary-color;
    font-weight: 700;
    position: relative;

    &:after {
        content: '\f107';
        font-family: $icon-font;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &:focus {
        outline: none;
    }
}

.checkout-index-index .payment-option._active .payment-option-title:after {
    content: '\f106';
}

.checkout-index-index .content__main button.action-auth-toggle {
    background-color: transparent;
    color: $primary-color;
    margin-bottom: 25px;
    padding: 0 !important;
    position: relative;

    &:after {
        content: '\f107';
        font-family: $icon-font;
    }
}

.checkout-index-index .block-customer-login .block-content {
    margin-bottom: 30px;
}

.checkout-index-index .block-customer-login .block-content form:focus {
    outline: none;
}

.checkout-index-index .opc-wrapper.am-opc-wrapper .payment-method-content,
.checkout-index-index .opc-wrapper.am-opc-wrapper .payment-option-content {
    margin-top: 5px;
    padding: 0 !important;
}

.checkout-index-index .opc-wrapper.am-opc-wrapper .payment-option-content .action-apply {
    font-size: 18px !important;
}

.checkout-index-index .col-method {
    color: $primary-color;
    font-weight: 700;
}

.checkout-index-index .additional-options .checkout-agreements .action-show {
    font-family: $primary-font;
    color: $primary-color !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 1.2 !important;
    margin-left: 10px;
    padding: 0 !important;
    text-transform: none !important;
}

.checkout-index-index .opc-wrapper.am-opc-wrapper .checkout-agreement {
    margin-bottom: 10px;
}

.checkout-index-index .opc-wrapper.am-opc-wrapper .checkout-agreement label {
    margin-bottom: 0;
}

.checkout-agreement label {
    line-height: 1;
}

.label > .mollie-payment-icon {
    display: inline-block;
}

.checkout-index-index .minicart-items {
    background-color: #fff;
}

.checkout-block .field[name="billingAddressshared.vat_id"], .checkout-block .field[name="shippingAddress.vat_id"] {
    display: none !important;
}

.product-item-details .toggle {
    cursor: pointer;
    display: block;
    position: relative;

    &:after {
        content: '\f107';
        font-family: $icon-font;
        margin-left: 5px;
    }

    &:focus {
        outline: none;
    }
}

.product-item-details .options.active .toggle:after {
    content: '\f106';
}

.product-item-details .product {
    display: block;
}

.product-item-details .content {
    background-color: #fff;
}

.product-item-details dd.values:not(:last-child) {
    margin-bottom: 5px;
}

.checkout-index-index .content__main .opc-wrapper.am-opc-wrapper {
    flex-direction: column;
}

.checkout-index-index .content__main .opc-wrapper.am-opc-wrapper.layout-3-columns .checkout-column {
    width: 100%;
}

.checkout-index-index .content__main .checkout-column .checkout-block {
    margin-left: 0;
    margin-right: 0;
}

.loading-mask {
    position: fixed;
    z-index: 1000;
    width: 300px;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.95);
    text-align: center;
    box-shadow: $box-shadow-default;
    top: 42%;
    left: 42%;
}

.loading-mask p {
    font-weight: bold;
    color: $primary-color;
}

.loader img {
    left: 41%;
    top: 33%;
}

.product-item img {
    height: auto;
    max-height: 100px;
    max-width: 100%;
    width: auto;

    .field-error span {
        color: #f00;
    }
}

.checkout-block .field.street.admin__control-fields legend {
    display: none;
}

.checkout-block .field-tooltip .label {
    display: none;
}

.table-checkout-shipping-method .col.col-method:nth-child(3) {
    display: none;
}

.table-checkout-shipping-method .col.col-price {
    padding-right: 10px;
}

.amcheckout-shipping-methods th.col-carrier {
    display: none;
}

.checkout-index-index label.admin__field-label {
    margin-left: 6px;
}

.checkout-success .order-details-items .order-title {
    display: none;
}

.checkout-index-index .opc-wrapper.am-opc-wrapper .checkout-billing-address .field-select-billing .label {
    display: block !important;
    font-size: 18px;
    margin: 15px 0 25px;
}

.checkout-index-index .opc-wrapper.am-opc-wrapper .label {
    word-break: break-word !important;

    &:after {
        content: "" !important;
    }
}

.checkout-index-index .checkout-block {
    margin: 7px 9px 25px !important;
}

.block.items-in-cart {
    .content {
        margin-top: 1rem !important;

        .minicart-items {
            display: flex !important;
            flex-direction: column !important;
            gap: 1rem !important;

            .product-item {
                margin-left: 0 !important;
            }

            .product {
                display: flex !important;
                gap: 1rem !important;

                .product-image-container {
                    height: 65px !important;
                    width: 65px !important;

                    .product-image-wrapper {
                        display: flex;
                        height: 65px !important;
                        width: 65px !important;

                        img {
                            aspect-ratio: 1 !important;
                            object-fit: cover !important;
                        }
                    }
                }

                .product-item-details {
                    padding-left: 0 !important;
                    line-height: 1.8 !important;
                }
            }
        }
    }
}

table.data.table.table-totals {
    line-height: 1.8 !important;
}

.checkout-payment-method:has(.form.form-discount) {
    margin-top: 1rem !important;
}

.form.form-discount {
    .field .label > span {
        display: none !important;
    }
}

.totals.discount {
    > th {
        display: flex;
        flex-direction: column;
        gap: .2rem;

        .title {
            font-size: 14px;
            margin-bottom: 0 !important;
        }

        .discount.coupon {
            font-style: italic;
        }
    }

    .price {
        color: $secondary-color-darker !important;
    }
}

.ordered .order-items .table-order-items {
    border-spacing: 0 !important;

    thead tr th {
        padding: .5rem .2rem;
    }

    tbody tr {
        &:nth-child(even) {
            background-color: #f4f4f4;
        }

        td {
            padding: .5rem .2rem;
        }

        td.col.price {
            .price-including-tax .cart-price .price {
                color: $primary-color;
                font-size: 1rem;
            }
            .price-excluding-tax .cart-price .price {
                color: #828282;
            }
        }

        td.col.qty {
            .item {
                margin-left: 0 !important;
            }
        }

        td.col.subtotal {
            .price-including-tax .cart-price .price {
                color: $primary-color;
                font-size: 1rem;
            }
            .price-excluding-tax .cart-price .price {
                color: #828282;
            }
        }
    }

    tfoot {
        th {
            text-align: left !important;
        }

        tr.subtotal {
            th, td .price {
                color: #000;
                font-size: 1rem;
                padding-bottom: .5rem;
            }
        }

        tr.discount {
            th, td .price {
                color: $secondary-color-darker;
                padding-bottom: .5rem;
            }
        }

        tr.totals-tax {
            th, td .price {
                color: #828282;
                padding-bottom: .5rem;
            }
        }

        tr.shipping {
            th, td .price {
                padding-bottom: .5rem;
            }
        }

        tr.grand_total {
            th, td strong .price {
                color: $primary-color;
                font-size: 1.2rem;
            }
        }
    }
}

@media screen and (max-width: $screen-small - 1px) {
    .checkout-index-index .content {
        margin-top: 155px;
    }

    .checkout-index-index .opc-wrapper.am-opc-wrapper.layout-3columns .checkout-column {
        width: 100% !important;
    }
}

@media screen and (min-width: $screen-small) {
    .checkout-index-index .content__main .opc-wrapper.am-opc-wrapper {
        flex-direction: row;
    }

    .checkout-index-index .content__main .opc-wrapper.am-opc-wrapper.layout-3-columns .checkout-column {
        width: 33.33%;
    }

    .checkout-index-index .content__main .checkout-column:first-child .checkout-block {
        margin-left: 0;
        margin-right: 18px;
    }

    .checkout-index-index .content__main .checkout-column:last-child .checkout-block {
        margin-left: 18px;
        margin-right: 0;
    }
}
